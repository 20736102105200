<template>
  <NuxtLink :to="`/activities/category/${props.slug}`" :no-prefetch="false">
    <div class="relative h-[400px] group">
      <div
        class="hidden group-hover:block absolute w-full h-full group-hover:bg-gradient-to-t from-black/50 transition-all duration-300 pb-8 z-10 overflow-hidden"
      >
        <div class="flex flex-col justify-between h-full">
          <div class="w-full grid place-items-end overflow-hidden">
            <div
              aria-label="Details"
              class="bg-black rounded-full h-16 w-16 flex items-center justify-center text-white -mt-5 -mr-5"
            >
              <Icon name="i-heroicons-arrow-right" class="h-5 w-5 -rotate-45 mr-4 mt-4" />
            </div>
          </div>
          <div class="text-white text-[40px] font-normal leading-7 pl-4">
            {{ props.name }}
          </div>
        </div>
      </div>
      <div class="overflow-hidden h-full bg-black">
        <NuxtImg :src="imageUrl" :alt="props.name" class="object-cover w-full h-full duration-300" format="webp" />
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { ImageCategory } from '@/types'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  images: {
    type: Array as PropType<ImageCategory[]>,
    default: () => []
  },
  slug: {
    type: String,
    required: true
  }
})

const imageUrl = computed(() => {
  return props.images[0]?.image ?? 'https://fakeimg.pl/300x300?text=activity'
})
</script>

<style></style>
